<template>
    <div class="page-content">
        <div class="container">
            <h1> {{ pageContent('courses') }} </h1>
            <div class="section-header pb-3 border-bottom rightalign">    
                <div class="section-header-right">
                            <!-- <a href="#" class="btn-filter" uk-tooltip="title: Course Filter ; pos:  top-right"
                        uk-toggle="target: #course-filter">
                        <i class="icon-feather-filter"></i>
                    </a> -->
                    <!-- <div class="display-as">
                        <a href="#" class="active" uk-tooltip="title:Grid View; pos: top-right" title="" aria-expanded="false">
                            <i class="icon-feather-grid"></i></a>
                        <a href="#?mode=list" class="" uk-tooltip="title:List View; pos: top-right" title="" aria-expanded="false">
                            <i class="icon-feather-list"></i></a>
                    </div>
                    <form action="#">
                        <select class="sort__select" name="sort">
                            <option value="">--Select--</option>
                            <option value="newest"> Newest </option>
                            <option value="popular" popular="">Popular</option>
                        </select>
                    </form> -->
                </div>
            </div>        
            <div class="section-small dashcourse">
                <CourseCard :courses="courseData" box="col-sm-3" />
                <div class="row" v-if="courseData.length < 1">
                    <div class="no-record">
                        <i class="icon-material-outline-school"></i>
                        <p>{{ pageContent('no_permission') }}</p>
                    </div>
                </div> 
            </div>
  
        </div>
    </div>
</template>

<script>
import CourseCard from "../Course/CourseCard.vue";
import Translation from '../../../public/translation.json'

export default {
    components: {
        CourseCard
    },
    data(){
        return {
            breadcrumb: [
                {title: 'Home', path:'/'},
                {title: 'Contact Us', path:'#'}
            ],
            filePath: '',
            pageData: {},
            courseData: [],
            categoryId: 0,
            boxSize: 'col-sm-3',
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getCourses() {
            let params = {};
            if(this.$route.params.categoryid) {
                params.category_id = this.$route.params.categoryid
            }
            this.$store.dispatch('userCourses', {params}).then(res=>{    
                if(res.data.status === true) {                    
                    this.courseData = res.data.data;
                    if(res.data.path)
						this.dirPath = res.data.path
                }
            })
            .catch(err=>{
                console.log(err.response.statusText)
                this.courseData = [];
            })
        }
    },
    created: function(){
        this.getCourses()
    },
    watch: {
        "$route.params.categoryid"() {
            this.getCourses()
        }
    }
}
</script>

<style scoped>
.sort__select{padding:0; margin-left: 1rem; margin-bottom: 0;}
.page-content {
    min-height: 700px;
}
</style>